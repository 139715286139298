<template>
  <b-card no-body>
    <SidebarSearch :components="searchComponent" @search="search = $event" />

    <TableHeader
      :selected-length="selectedDatas.length"
      :per-page="perPage"
      :has-create-button="false"
      @per-page-change="perPage = $event"
    >
      <template #dropdown-items>
        <b-dropdown-item :disabled="selectedDatas.length === 0">
          <Feather-icon icon="CopyIcon" />
          Salin ke Produk Utama ({{ selectedDatas.length }})
        </b-dropdown-item>
        <b-dropdown-item :disabled="selectedDatas.length === 0">
          <Feather-icon icon="LinkIcon" />
          Tautkan SKU ({{ selectedDatas.length }})
        </b-dropdown-item>
      </template>

      <template #buttons-group>
        <!-- sinkron button -->
        <b-button variant="success" size="sm" @click="syncProduct">
          <Feather-icon icon="RefreshCwIcon" />
          Sinkron Produk
        </b-button>
      </template>
    </TableHeader>

    <b-table-simple
      striped
      responsive
      style="zoom: 80%"
      class="position-relative"
    >
      <b-thead>
        <b-tr>
          <b-th class="bg-transparent" style="width: 5%">
            <b-form-checkbox v-model="selectAll" />
          </b-th>
          <b-th class="bg-transparent" style="width: 50%"
            >Nama Toko dan Produk</b-th
          >
          <b-th class="bg-transparent" style="width: 40%"
            >Rincian Produk per SKU</b-th
          >
          <b-th class="bg-transparent" style="width: 5%"></b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-if="tableData.length > 0">
        <b-tr v-for="(item, index) in tableData" :key="index">
          <b-td>
            <b-form-checkbox v-model="selectedDatas" :value="item" />
          </b-td>
          <b-td>
            <div class="d-flex flex-column">
              <div class="mb-1 mt-1">
                <b-img
                  :src="require('@/assets/images/marketplace/lazada.png')"
                  :alt="item.name"
                  style="width: 24px; height: 24px"
                />
                {{ item.name }}
                <hr />
              </div>
              <h5 class="text-primary">
                {{ item.product.attributes.name }}
              </h5>

              <div
                v-if="formattedAttributes(item.product.attributes).length > 1"
              >
                <div class="custom-hr mb-0">
                  <div class="hr-container">
                    <hr class="hr-line" />
                    <span class="hr-text"><strong>Atribut Produk</strong></span>
                    <hr class="hr-line" />
                  </div>
                </div>
                <div class="d-flex flex-column mt-1">
                  <dl
                    v-for="(atr, x) in formattedAttributes(
                      item.product.attributes,
                    )"
                    :key="x"
                    class="row my-0"
                  >
                    <dt class="col-sm-6">{{ atr.key }} :</dt>
                    <dd>{{ atr.value }}</dd>
                  </dl>
                </div>
              </div>
              <div v-else style="border-top: 3px dashed #f0f0f0">
                <p class="text-danger mt-1">
                  *** atribut tidak ada atau tidak tersedia ***
                </p>
              </div>
            </div>
          </b-td>
          <b-td>
            <b-table-simple responsive small>
              <b-thead>
                <b-tr>
                  <b-th class="bg-transparent text-center" style="width: 3%"
                    >#</b-th
                  >
                  <b-th class="bg-transparent" style="width: 20%"
                    >Varian Produk</b-th
                  >
                  <b-th class="bg-transparent text-right" style="width: 5%"
                    >Harga</b-th
                  >
                  <b-th class="bg-transparent text-right" style="width: 5%"
                    >Stok</b-th
                  >
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(sku, idx) in item.product.skus"
                  :key="idx"
                  style="border-bottom: 2px dashed #f0f0f0"
                >
                  <b-td class="text-center">{{ idx + 1 }}</b-td>
                  <b-td>
                    <div class="d-flex align-items-center">
                      <!-- Image -->
                      <b-img
                        width="60"
                        :src="'https://cdn.statically.io/avatar/Pr'"
                        :alt="'product #' + index"
                        thumbnail
                        class="mr-2"
                      />

                      <!-- Content -->
                      <div class="flex-grow-1">
                        <div class="d-flex flex-column mb-1">
                          <small>
                            <b>SKU Penjual :</b> <br />
                            {{ sku.SellerSku }}
                          </small>
                          <!-- MSKU -->
                          <small>
                            <b>MSKU :</b> <br />
                            {{ sku.masterSKU ? sku.masterSKU : 'Tidak Ada' }}
                          </small>
                        </div>
                      </div>
                    </div>
                  </b-td>
                  <b-td class="text-right">
                    <b>
                      {{ formattedCurrency(sku.price) }}
                    </b>
                  </b-td>
                  <b-td class="text-right">
                    {{ formattedNumber(sku.quantity) }}
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tfoot>
                <b-tr>
                  <b-td colspan="4" class="text-right">
                    <div class="d-flex justify-content-end">
                      <!-- tautkan sku -->
                      <b-button variant="link" size="sm" class="mr-1">
                        <feather-icon icon="LinkIcon" />
                        Tautkan
                      </b-button>
                      <b-button variant="link" size="sm" class="mr-1">
                        <Feather-icon icon="CopyIcon" />
                        Salin
                      </b-button>
                      <b-button
                        variant="link"
                        size="sm"
                        class="mr-1"
                        disabled
                        title="Fitur belum tersedia"
                        style="cursor: not-allowed"
                      >
                        <Feather-icon icon="Edit2Icon" />
                        Ubah
                      </b-button>
                      <b-button
                        variant="link"
                        size="sm"
                        disabled
                        title="Fitur belum tersedia"
                        style="cursor: not-allowed"
                      >
                        <Feather-icon icon="Trash2Icon" />
                        Hapus
                      </b-button>
                    </div>
                  </b-td>
                </b-tr>
              </b-tfoot>
            </b-table-simple>
          </b-td>
          <b-td>
            <b-dropdown right variant="outline-primary" size="md">
              <template #button-content>
                <Feather-icon icon="MoreHorizontalIcon" />
              </template>
              <!-- <b-dropdown-item>Salin > Master</b-dropdown-item> -->
              <b-dropdown-item>
                <Feather-icon icon="CopyIcon" />
                Salin SKU ke Master ({{ item.product.skus.length }})
              </b-dropdown-item>
              <b-dropdown-item disabled title="Fitur belum tersedia">
                <Feather-icon icon="Edit2Icon" /> Ubah Produk
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item
                variant="danger"
                disabled
                title="Fitur belum tersedia"
              >
                <Feather-icon icon="Trash2Icon" />
                Hapus Produk
              </b-dropdown-item>
            </b-dropdown>
          </b-td>
        </b-tr>
      </b-tbody>
      <EmptyTableRow v-else :colspan="7" />
    </b-table-simple>
  </b-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import axios from '@axios';

// import mixins
import marketplaceMixins from './mixins/marketplaceMixins';

export default defineComponent({
  name: 'MarketplaceLazadaList',
  mixins: [marketplaceMixins],
  data() {
    return {
      searchComponent: [
        {
          name: 'Pencarian Produk',
          placeholder: 'Cari produk (nama, sku)',
          type: 'text',
          value: '',
        },
        {
          label: 'Urutkan berdasarkan',
          name: 'sortBy',
          type: 'select',
          value: null,
          options: [
            { value: '1', text: 'Tanggal (Terbaru)' },
            { value: '2', text: 'Tanggal (Terlama)' },
            { value: '3', text: 'Harga (Tertinggi)' },
            { value: '4', text: 'Harga (Terendah)' },
            { value: '5', text: 'Stok (Tertinggi)' },
            { value: '6', text: 'Stok (Terendah)' },
          ],
        },
        {
          label: 'Kondisi Produk',
          name: 'condition',
          type: 'select-multiple',
          value: null,
          options: [
            { value: '1', title: 'Baru' },
            { value: '2', title: 'Bekas' },
          ],
        },
        {
          label: 'Status Produk',
          name: 'status',
          type: 'select-multiple',
          value: null,
          options: [
            { value: '1', title: 'Aktif' },
            { value: '2', title: 'Best (Feature Product)' },
            { value: '3', title: 'Tidak Aktif (Gudang)' },
            { value: '-1', title: 'Pending' },
            { value: '-2', title: 'Banned' },
            { value: '0', title: 'Terhapus' },
          ],
        },
      ],
    };
  },
  methods: {
    async fetchData() {
      if (this.tableData.length > 1) {
        if (this.tableData.length >= this.totalRows) {
          return;
        }
      }

      const params = {
        page: this.currentPage,
        limit: this.perPage,
        ns: false,
      };

      if (this.search.length > 0) {
        params.search = this.search;
      }

      const url = Object.keys(params)
        .map(key => `${key}=${params[key]}`)
        .join('&');

      const response = await axios.get(`lazada/product/sync?${url}`);

      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.data &&
        response.data.data.data.length > 0
      ) {
        this.tableData = [...this.tableData, ...response.data.data.data];
      }

      this.totalRows = response.data.data.total;
      this.currentPage += 1;
    },

    selectAllRows() {
      if (this.selectAll) {
        this.selectedDatas = this.tableData;
      } else {
        this.selectedDatas = [];
      }
    },
  },
  computed: {
    formattedCurrency() {
      return price =>
        new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(price);
    },
    formattedNumber() {
      return stock => new Intl.NumberFormat('id-ID').format(stock);
    },
    formattedAttributes() {
      return attributes => {
        const excludeAttributes = [
          'description',
          'description_en',
          'short_description',
          'delivery_option_sof',
          'promotion_whitebkg_image',
          'name',
          'source',
        ];

        const attributesArray = Object.keys(attributes)
          .filter(key => !excludeAttributes.includes(key))
          .map(key => ({
            key: key
              .replace(/_/g, ' ')
              .replace(/(^\w|\s\w)/g, m => m.toUpperCase()),
            value: attributes[key],
          }));

        return attributesArray;
      };
    },
  },
});
</script>

<style lang="scss" scoped>
/* Custom styling for the horizontal rule */
.custom-hr {
  text-align: center;
  margin: 20px 0;
}

.hr-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hr-line {
  flex: 1;
  border-top: 1px solid #ddd; /* Color of the horizontal rule */
  margin: 0 5px; /* Adjust the spacing between the lines and text */
}

.hr-text {
  background-color: #fff; /* Background color of the text */
  padding: 0 10px; /* Padding around the text */
  color: #777; /* Text color */
}
</style>
