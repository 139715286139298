import {
  BRow,
  BCol,
  BCard,
  BButton,
  BTableSimple,
  BTbody,
  BTr,
  BTd,
  BThead,
  BTh,
  BMedia,
  BFormCheckbox,
  BImg,
  BLink,
  BBreadcrumb,
  BBreadcrumbItem,
  BBadge,
  BDropdownItem,
  BDropdown,
  BDropdownDivider,
  BTfoot,
} from 'bootstrap-vue';
import SidebarSearch from '@/components/SidebarSearch.vue';
import TableHeader from '@/components/TableHeader.vue';
import EmptyTableRow from '@/components/EmptyTableRow.vue';
import axios from '@axios';

const marketplaceMixins = {
  components: {
    SidebarSearch,
    TableHeader,
    EmptyTableRow,
    BRow,
    BCol,
    BCard,
    BButton,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BThead,
    BTh,
    BMedia,
    BFormCheckbox,
    BImg,
    BLink,
    BBreadcrumb,
    BBreadcrumbItem,
    BBadge,
    BDropdownItem,
    BDropdown,
    BDropdownDivider,
    BTfoot,
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      selectedDatas: [],
      selectAll: false,
      search: '',
      productData: {},
      shops: [],
      isFetching: false,
      routeName: this.$route.name.replace('Products', '').toLowerCase(),
    };
  },
  async created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  async mounted() {
    await this.fetchData();
    await this.getMarketplace();
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    perPage() {
      this.searchData();
    },
  },
  methods: {
    handleScroll() {
      const isAtBottom =
        document.documentElement.scrollHeight -
        (window.innerHeight + window.scrollY) <=
        1;

      if (isAtBottom && !this.isFetching) {
        this.isFetching = true;

        this.fetchData();

        setTimeout(() => {
          this.isFetching = false;
        }, 1800);
      }
    },

    async getMarketplace() {
      this.shops = [];
      this.searchComponent[1].options = [];

      // Fetch marketplace data from server
      const response = await axios.get('/marketplace');

      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        // filter data marketplaceName
        const mkt = response.data.data.filter(
          m => m.marketplaceName === this.routeName,
        );

        // sesuaikan dengan format b-form-select
        this.shops = mkt.map(shop => ({
          text: shop.shopName,
          value: shop.id,
          marketplace: shop.marketplaceName,
          status: shop.aprovalStatus === 'approved' ? 'Verified' : shop.aprovalStatus.charAt(0).toUpperCase() + shop.aprovalStatus.slice(1),
        }));

        const routePath = this.$route.path;

        if (routePath.includes('/products/')) { // check if route path contains /products/
          const marketplace = routePath.split('/').pop(); // get marketplace name by split path and get last index
          this.searchComponent[1].options = this.shops.filter(
            shop => shop.marketplace === marketplace,
          ); // filter shop by marketplace
        }
      }
    },

    async syncProduct() {
      const formattedOptions = {};

      // filter empty value
      // this.shops.push({ text: 'Toko 2123', value: '66c00a86460e02782e2c537a', status: 'Pending' });
      const shops = this.shops.filter(shop => shop.value !== '');
      shops.forEach(shop => {
        formattedOptions[shop.value] = { name: shop.text, status: shop.status };
      });

      // buat select toko dari this.shops
      this.$swal({
        title: 'Pilih Toko',
        html: `
          <div style="max-height: 300px; overflow-y: auto; font-size: 0.8em;">
            <table style="width: 100%; border-collapse: collapse; font-size: 0.8em;">
              <thead>
                <tr style="position: sticky; top: 0; background: #f8f9fa; z-index: 10;">
                  <th style="text-align: left; padding: 12px; border-bottom: 2px solid #dee2e6;">
                    <input type="checkbox" id="selectAll" style="cursor: pointer;">
                  </th>
                  <th style="text-align: left; padding: 12px; border-bottom: 2px solid #dee2e6;">Nama Toko</th>
                  <th style="text-align: left; padding: 12px; border-bottom: 2px solid #dee2e6;">Status</th>
                  <th style="text-align: left; padding: 12px; border-bottom: 2px solid #dee2e6;">
                    <input type="text" id="searchInput" placeholder="🔍 Cari Toko" style="width: 100%; padding: 10px; border-radius: 5px; border: 1px solid #ced4da; transition: border-color 0.3s;">
                  </th>
                </tr>
              </thead>
              <tbody id="shopList">
                ${Object.entries(formattedOptions).map(([value, { name, status }]) => `
                  <tr style="border-bottom: 1px solid #dee2e6;">
                    <td style="text-align: left; padding: 10px;"><input type="checkbox" class="shop-checkbox" id="${value}" value="${value}" style="cursor: pointer;"></td>
                    <td style="text-align: left; padding: 10px;"><label for="${value}">${name}</label></td>
                    <td style="text-align: left; padding: 10px;">
                      ${status === 'Verified' 
                        ? `<span class="badge badge-success" style="padding: 5px 10px; border-radius: 5px;">${status}</span>`
                        : `<span class="badge badge-danger" style="padding: 5px 10px; border-radius: 5px;">${status}</span>`}
                    </td>
                    <td style="text-align: left; padding: 10px;"></td>
                  </tr>
                `).sort(() => Math.random() - 0.5).join('')}
              </tbody>
            </table>
          </div>
          <div style="text-align: left; margin-top: 10px; position: sticky; bottom: 0; background: #f8f9fa; z-index: 10; font-size: 0.6em; padding: 10px; border-top: 1px solid #dee2e6;">
            <strong><span id="selectedCount">0</span> Toko Terpilih</strong>
            <span id="dynamicMessage"></span>
          </div>
        `,
        showCancelButton: true,
        confirmButtonText: 'Sync',
        cancelButtonText: 'Batal',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          const searchInput = document.getElementById('searchInput');
          const shopList = document.getElementById('shopList');
          const selectAllCheckbox = document.getElementById('selectAll');
          const shopCheckboxes = document.getElementsByClassName('shop-checkbox');
          const selectedCount = document.getElementById('selectedCount');
          const confirmButton = document.querySelector('.swal2-confirm');

          // Enable the confirm button initially
          confirmButton.disabled = true;

          searchInput.addEventListener('input', function() {
            const searchTerm = this.value.toLowerCase();
            Array.from(shopList.getElementsByTagName('tr')).forEach(row => {
              const text = row.textContent.toLowerCase();
              row.style.display = text.includes(searchTerm) ? '' : 'none';
            });
          });

          selectAllCheckbox.addEventListener('change', function() {
            Array.from(shopCheckboxes).forEach(checkbox => {
              checkbox.checked = this.checked;
            });
            selectedCount.textContent = this.checked ? shopCheckboxes.length : 0;
            confirmButton.disabled = shopCheckboxes.length === 0 || selectedCount.textContent === '0';
          });

          Array.from(shopCheckboxes).forEach(checkbox => {
            checkbox.addEventListener('change', function() {
              selectAllCheckbox.checked = Array.from(shopCheckboxes).every(cb => cb.checked);
              selectedCount.textContent = Array.from(shopCheckboxes).filter(cb => cb.checked).length;
              confirmButton.disabled = selectedCount.textContent === '0';
            });
          });
        },
        preConfirm: () => {
          return Array.from(document.querySelectorAll('.shop-checkbox:checked')).map(checkbox => checkbox.value);
        }
      }).then(async res => {
        if (res.isConfirmed) {
          const shopIds = res.value;

          this.$swal({
            title: `Sinkronisasi ${shopIds.length} Toko`,
            text: 'Sinkronisasi produk sedang berlangsung...',
            icon: 'info',
            showConfirmButton: false,
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });

          const params = { ns: false, ...(shopIds.length === shops.length ? { sync: 'all' } : { shopId: shopIds.join(',') }) };
          const response = await axios.post(`${this.routeName}/product/sync`, null, { params });
          
          if (response && response.data && response.status === 200) {
            this.$swal({
              title: 'Sinkronisasi Produk',
              text: 'Sinkronisasi produk berhasil!',
              icon: 'success',
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonText: 'OK',
            }).then(() => {
              // Reload halaman setelah tombol OK diklik
              location.reload();
            });
          }
        }
      });
    },
  },
};

export default marketplaceMixins;
